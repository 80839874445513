import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useMemo } from 'react';
import { useGetNeedFinalizationStep } from '../../hooks/useNeedFinalizationStep';
import { FIELD_CROP_CREATION_SOURCE_SLUGS } from '@shared/entities/fieldCrop/fieldCrop.types';
import { useCropDestinationStep } from '../../hooks/useCropDestinationStep';

export const ROTATION_PROGRESS_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    PENDING: 'PENDING',
    FINISHED: 'FINISHED',
} as const;

export type RotationProgressStatusT = (typeof ROTATION_PROGRESS_STATUSES)[keyof typeof ROTATION_PROGRESS_STATUSES];

export const useIntroductionContentProgress = () => {
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { needFinalization } = useGetNeedFinalizationStep();
    const progressDone = progressState.getBySlug('rotation')?.progress_done ?? 0;
    const { currentCropDestinationProgress, maxCropDestinationProgress } = useCropDestinationStep();

    const maxFieldCropProgress = fieldState.list.length || 1;
    const currentFieldCropProgress = useMemo(
        () =>
            Object.values(Object.fromEntries(fieldCropState.list.map((fieldCrop) => [fieldCrop.farm_season_field_id])))
                .length,
        [fieldCropState.list],
    );

    /** current field progress can be 0 (undone) or 1 (done = any field created) */
    const currentFieldProgress = fieldState.list.length ? 1 : 0;
    /** current crop progress can be 0 (undone) or 1 (done = any crop created) */
    const currentCropProgress = cropState.list.length && progressDone > 0 ? 1 : 0;

    let progressStatus: RotationProgressStatusT = ROTATION_PROGRESS_STATUSES.NOT_STARTED;

    const currentFinalizationStepProgress = fieldCropState.list.some(
        (fieldCrop) => fieldCrop.creation_source === FIELD_CROP_CREATION_SOURCE_SLUGS.PAC,
    )
        ? 0
        : 1;

    if (
        currentFieldCropProgress === maxFieldCropProgress &&
        currentCropDestinationProgress === maxCropDestinationProgress &&
        (!needFinalization || currentFinalizationStepProgress === 1)
    )
        progressStatus = ROTATION_PROGRESS_STATUSES.FINISHED;
    else if (currentFieldProgress > 0) progressStatus = ROTATION_PROGRESS_STATUSES.PENDING;

    const isFieldCropAssignationDone = currentFieldCropProgress === maxFieldCropProgress;

    return {
        currentFieldCropProgress,
        maxFieldCropProgress,
        currentCropDestinationProgress,
        maxCropDestinationProgress,
        maxFieldProgress: 1,
        currentFieldProgress,
        maxCropProgress: 1,
        currentCropProgress,
        currentFinalizationStepProgress,
        maxFinalizationStepProgress: 1,
        progressStatus,
        needFinalization,
        isFieldCropAssignationDone,
    };
};
