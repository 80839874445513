import { makeStyles } from '@soil-capital/ui-kit/style';

export const useUserMenuStyles = makeStyles()((theme) => ({
    menu: {
        padding: theme.spacing(1.5),

        '& .MuiPaper-root': {
            minWidth: 200,

            '& .MuiInputBase-root': {
                width: '100%',
            },
        },

        '& .MuiList-root': {
            gap: 0,
        },

        '& .MuiMenuItem-root': {
            padding: 0,
        },
    },
    email: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontSize: 12,
        fontWeight: 'bold !important',
        maxWidth: '250px',
    },
    select: {
        height: 40,
        color: theme.palette.primaryScale[900],
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    logout: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    termsOfUse: {
        fontSize: 12,
        color: theme.palette.darkScale[500],
        textDecoration: 'underline',
        textAlign: 'center',
        width: '100%',
        cursor: 'pointer',
        marginTop: theme.spacing(1),
    },
    version: {
        textAlign: 'center',
        width: '100%',
        color: theme.palette.darkScale[500],
        fontSize: 12,
        marginTop: theme.spacing(1),
    },
}));
