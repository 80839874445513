/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from 'react';
import MapboxDraw, { MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { MapContext } from '../utils/MapProvider';
import { FieldT } from '@shared/entities';
import { PolygonGeometryT } from '@shared/entities/field/field.types';
import { useEffect, useCallback, useMemo } from 'react';
import { calculateArea, isDrawingInsideBoundaries, isDrawingOnOtherPolygons } from '../utils/polygonHelper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const CustomDirectSelect = { ...MapboxDraw.modes.direct_select };

CustomDirectSelect.onDrag = function (state, e) {
    // Call the original onDrag function
    if (MapboxDraw.modes.direct_select && MapboxDraw.modes.direct_select.onDrag) {
        MapboxDraw.modes.direct_select.onDrag.call(this, state, e);
    }
    // Set a flag indicating dragging is in progress
    state.dragging = true;
};

CustomDirectSelect.onMouseUp = function (state, e) {
    // Call the original onMouseUp function
    if (MapboxDraw.modes.direct_select && MapboxDraw.modes.direct_select.onMouseUp) {
        MapboxDraw.modes.direct_select.onMouseUp.call(this, state, e);
    }
    // Check if a drag was happening
    if (state.dragging) state.dragging = false;
};

export const useEdit = () => {
    const mapContext = useContext(MapContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { map, fields, setFields, setEditMode, editMode, drawMode, splitMode, mergeMode, farmBoundaries } =
        mapContext ?? {};
    const [drawControl, setDrawControl] = useState<null | MapboxDraw>(null);
    const [editedFeatureId, setEditedFeatureId] = useState<null | string>(null);
    const [editedField, setEditedField] = useState<FieldT | null>(null);
    const [updatedField, setUpdatedField] = useState<FieldT | null>(null);
    const [isSaveDisabled, setIsSavedDisabled] = useState(false);
    const drawOptions = useMemo(
        () =>
            new MapboxDraw({
                displayControlsDefault: false,
                modes: {
                    ...MapboxDraw.modes,
                    direct_select: CustomDirectSelect,
                },
            }),
        [],
    );

    const handleDrawUpdate = useCallback(
        (e: MapMouseEvent) => {
            if (fields) {
                const point = [e.lngLat.lng, e.lngLat.lat];
                let intersectedField: FieldT | null = null;

                for (const field of fields) {
                    const polygon = field.polygon.geometry;
                    const isInside = booleanPointInPolygon(point, polygon);

                    if (isInside) {
                        intersectedField = field;
                        break;
                    }
                }

                if (intersectedField && !editedField) {
                    setEditedField?.(intersectedField);
                    setFields?.(fields.filter((field) => field !== intersectedField));

                    const featureId = drawOptions.add(intersectedField.polygon);
                    setEditedFeatureId(featureId[0]);
                    drawOptions.changeMode('direct_select', { featureId: featureId[0] });
                }
            }
        },
        [fields, editedField, drawOptions, setEditedField, setFields],
    );

    useEffect(() => {
        const onUpdate = (e: { features: any[] }) => {
            // Check if the updated feature is the one being edited
            if (e.features && e.features[0].id === editedFeatureId) {
                const drawingInsideBoundaries = isDrawingInsideBoundaries(e.features[0].geometry, farmBoundaries);
                if (!drawingInsideBoundaries) {
                    enqueueSnackbar(t('encoding-rotation.map.draw.boundaries-error'), {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                    setIsSavedDisabled(true);
                } else if (drawingInsideBoundaries) setIsSavedDisabled(false);
            }
        };

        // Ensure drawOptions is available
        if (drawOptions && map) {
            map.on('draw.update', onUpdate);
        }

        // Clean up the event listener on unmount or when dependencies change
        return () => {
            if (drawOptions && map) {
                map.off('draw.update', onUpdate);
            }
        };
    }, [drawOptions, editedFeatureId, map, enqueueSnackbar, t, farmBoundaries]);

    const edit = () => {
        if (map) {
            setEditMode?.(true);
            setUpdatedField(null);
            if (drawControl) {
                map.removeControl(drawControl);
            }
            map.addControl(drawOptions);
            setDrawControl(drawOptions);
        }
    };

    const stopEditing = () => {
        if (editedField && fields) setFields?.([editedField, ...fields]);
        if (drawControl) map?.removeControl(drawControl);
        setDrawControl(null);
        setEditMode?.(false);
        setEditedField(null);
    };

    const saveUpdatedField = (id: string) => {
        const updatedFeature = drawOptions.get(id);
        if (!updatedFeature || !editedField) return;
        const updatedPolygon = updatedFeature.geometry as PolygonGeometryT;
        const unEditedFieldsGeometry = fields?.map((field) => field.polygon.geometry) || [];

        if (!isDrawingOnOtherPolygons(updatedPolygon, unEditedFieldsGeometry)) {
            const newArea = calculateArea(updatedPolygon);
            setUpdatedField({
                ...editedField,
                original_area: Number(newArea.toFixed(3)),
                area: Number(newArea.toFixed(3)),
                polygon: { ...editedField?.polygon, geometry: updatedPolygon },
            });
        } else {
            enqueueSnackbar(t('encoding-rotation.pac-file.edit-fields-toast.error'), {
                variant: 'error',
            });
        }
        stopEditing();
    };

    useEffect(() => {
        if (editMode) {
            map?.on('click', handleDrawUpdate);
        }
        return () => {
            map?.off('click', handleDrawUpdate);
        };
    }, [map, handleDrawUpdate, editMode]);

    return {
        edit,
        stopEditing,
        editMode,
        drawMode,
        splitMode,
        mergeMode,
        saveUpdatedField,
        editedFeatureId,
        updatedField,
        isSaveDisabled,
    };
};
